:root {
  --reach-slider: 1;
}

[data-reach-slider] {
  padding: 20px;
}

[data-reach-slider-orientation="horizontal"] {
  width: 250px;
  height: 4px;
  padding-bottom: 30px;
}

[data-reach-slider-orientation="vertical"] {
  width: 4px;
  height: 250px;
  padding-right: 30px;
}

[data-reach-slider-track] {
  border-radius: 2px;
  background: #f2f2f2;
}

[data-reach-slider-track-orientation="horizontal"] {
  width: 100%;
  height: inherit;
}

[data-reach-slider-track-orientation="vertical"] {
  width: inherit;
  height: 100%;
}

[data-reach-slider-handle] {
  width: 12px;
  height: 12px;
  border: 2px solid #1159a6;
  background: #fff;
  border-radius: 10px;
  z-index: 1;
  transform-origin: center;
}

[data-reach-slider-handle-orientation="horizontal"] {
  top: 50%;
  transform: translateY(-50%);
}

[data-reach-slider-handle-orientation="horizontal"]:focus {
  transform: translateY(-50%);
}

[data-reach-slider-handle-orientation="vertical"] {
  left: 50%;
  transform: translateX(-50%);
}

[data-reach-slider-handle-orientation="vertical"]:focus {
  transform: translateX(-50%);
}

[data-reach-slider-track-highlight] {
  border-radius: inherit;
  background: #1159a6;
}

[data-reach-slider-track-highlight-orientation="horizontal"] {
  height: 100%;
}

[data-reach-slider-track-highlight-orientation="vertical"] {
  width: 100%;
}

[data-reach-slider-marker] {
  width: 8px;
  height: 8px;
  background: inherit;
  border: 1px solid #fff;
  border-radius: 4px;
  transform-origin: center;
}

[data-reach-slider-marker-orientation="horizontal"] {
  top: 50%;
  transform: translate(-50%, -50%);
}

[data-reach-slider-marker-orientation="vertical"] {
  left: 50%;
  transform: translate(-50%, -50%);
}

[data-reach-slider-marker-highlight] {
  background: #1159a6;
}

[data-reach-slider-marker] > span {
  position: absolute;
  font-size: 12px;
}

[data-reach-slider-marker-orientation="horizontal"] > span {
  top: calc(100% + 5px);
  left: 50%;
  text-align: center;
  transform: translateX(-50%);
}

[data-reach-slider-marker-orientation="vertical"] > span {
  left: calc(100% + 5px);
  top: 50%;
  text-align: right;
  transform: translateY(-50%);
  white-space: nowrap;
}
